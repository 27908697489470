$(function(){
  // get the root site url
  var root = window.location.origin;
  // get first parent, -> after root but before second /
  var p1 = window.location.href.substr( root.length + 1 );
  var arr = p1.split("/");
  var page = window.location.href.substr( window.location.href.lastIndexOf("/") + 1 );
  // strip any - from string
  // page = page.replace('-', ' ');
  if( page != '' ){
    var menuParent = arr[0];
    $(".upper-nav a").each(function(){
        var element = $(this).attr('href').substr(1);
        if ( element == menuParent ){
          $( this ).addClass('active');
          $( this ).attr('aria-label', 'current parent');
        }
    });
    var lowerMenuParent = arr[1];
  }
  // add active class to bottom menu
  // check if page is the parent
  $(".navi ul li").each(function(){
      // find parent in menu item
      if( $(this).hasClass('parent') ){
        // find the first anchor
        var i = $(this).find('a:first').text().toLowerCase();
        if(lowerMenuParent != null && lowerMenuParent.indexOf('-')){
          lowerMenuParent = lowerMenuParent.replace('-', ' ');
        }
        // underline the menu item if page matches the
        if( page === i ){      // page we are on is
          $(this).find('a:first').addClass('active-link');
          $( this ).attr('aria-label', 'current page');
        }
        if( lowerMenuParent === i ){
          $(this).find('a:first').addClass('active-link');
          $( this ).attr('aria-label', 'current page');
        }
      }
  });
});


$(document).on('change','#select_country',function(){
  var country = $("#select_country option:selected").text();
  if( country == "United States" ){
    $('#select_state').prop('disabled', false);
  }else{
    $('#select_state').prop('disabled', true);
  }
  window.location.href = '?&location='+country+'&#sales-reps';
});

$(document).on('change','#select_state',function(){
  var state = $('#select_state option:selected').text();
  window.location.href = '?&location='+state+'&#sales-reps';

});

$(document).on('click', 'input[name*="checkWhitepaper"]', function(){
  // which one was checked
  var id = $(this).attr('id');
  if( $(this).is(':checked') ){
    var link = 'https://s3.us-east-2.amazonaws.com/butterbuds/whitepapers/'+ $(this).val() ;
    $('#downloads').append('<li data-list-id="'+id+'"><a class="download-links" target="_blank" href="'+ link +'"><i class="fa fa-download pr-3"></i> '+ $(this).val() +'</a></li>');
  }else{
    // remove element
    $('#downloads li').each( function(i){
      var d = $(this).attr('data-list-id');
      if(d == id){
        // remove download
        $(this).empty();
      }
    });
  }
});

$(document).on('change','#select_country2',function(){
  var country = $("#select_country2 option:selected").text();
  history.pushState({page:1},'title1','?&location='+country+'&#distributors');

  var ul = [];
  var html = [];
  $('#distributors').hide();
  $('#selectedCard').empty();
  // display the selected countries card - loop through distributorIndex_ and find country selected
  $('#distributors .card-body ul li').each( function(){
    var currentUL     = ( $(this).parent().attr('id') );
    var ulID          = currentUL.substr(17);
    if( country == $( this ).text() ){
      ul.push( ulID );
      html.push( $( '#distributorCard_'+ulID ).html() );
      //console.log( $( '#distributorCard_'+ulID ).html() );
    }
  });

  for( var i = 0;i<ul.length;i++ ){
    $('#selectedCard').append('<div class=\"card col-sm-3 px-0 py-0\" >' + html[i] + '</div>' );
  }

  $('[id^="row_"]').each( function(e){
    //console.log('removing line related to row_'+$(this).attr('id'));
    $(this).hide();
  });


  $('#distributors').show();

});


$(function () {
    $("#formulaRequest").on({
        submit: function () {
            preventDefault();
            var $self = $(this);
            $(".alert.alert-success").remove();
            $("button[type=submit]", $self).prop("disabled", true);

            $.ajax({
                type: "post",
                dataType: "json",
                data: $(this).serialize(),
                success: function (response) {
                    $("ul.errors.help-block", $self).remove();
                    $(".has-error", $self).removeClass("has-error");

                    if ( response.success && response.finished ){
                        $self[0].reset();

                        $('#my-list').empty();
                        $('#form-input-selectedItems').empty();
                        listItemsID = [];
                        listItemsText = [];

                    } else if (response.errors) {
                        for (var key in response.errors) {
                            if (!response.errors.hasOwnProperty(key)) continue;
                            var messages = response.errors[key];
                            var $errors  = $("<ul>", {"class": "errors help-block"});
                            for (var i = 0; i < messages.length; i++) {
                                var $li = $("<li>");
                                $li.html(messages[i]);
                                $errors.append($li);
                            }

                            var $input = $("*[name=" + key + "], *[name='" + key + "[]']");

                            var $formGroup = $input.parents(".form-group");
                            $formGroup.addClass("has-error");

                            $formGroup.append($errors);
                        }
                    }

                    $("button[type=submit]", $self).prop("disabled", false);
                }
            });

            return false;
        }
    });
});

$(function () {
    $("#frmWhitePaper").on({
        submit: function () {
            var $self = $(this);
            var $download = $('#downloads').html();

            //console.log($download);
            $('#downloads').empty();
            $(".alert.alert-success").remove();
            $("button[type=submit]", $self).prop("disabled", true);

            $.ajax({
                type: "post",
                dataType: "json",
                data: $(this).serialize(),
                success: function (response) {
                    $("ul.errors.help-block", $self).remove();
                    $(".has-error", $self).removeClass("has-error");

                    if (response.success && response.finished) {
                        $self[0].reset();
                        var $successMessage = $("<div>", {"class": "alert alert-success"})
                            .append('<p>Your download should be made available!</p><ul class="downloads">'+$download+'</ul>', {"class": "lead"});

                        $self.before($successMessage);

                    } else if (response.errors) {
                        for (var key in response.errors) {
                            if (!response.errors.hasOwnProperty(key)) continue;
                            var messages = response.errors[key];
                            var $errors  = $("<ul>", {"class": "errors help-block"});
                            for (var i = 0; i < messages.length; i++) {
                                var $li = $("<li>");
                                $li.html(messages[i]);
                                $errors.append($li);
                            }

                            var $input = $("*[name=" + key + "], *[name='" + key + "[]']");

                            var $formGroup = $input.parents(".form-group");
                            $formGroup.addClass("has-error");

                            $formGroup.append($errors);
                        }
                    }

                    $("button[type=submit]", $self).prop("disabled", false);
                }
            });

            return false;
        }
    });
});

$(document).ready(function(){
  $('a[href*=".pdf"]').on('click', function (){
      ga('send', 'event', 'download', 'click',location.pathname);
  });
});

$(function(){
  $('#filterEvents').click( function(){
    var dt = $('#dateTo').val();
    var df = $('#dateFrom').val();
    // iterate through events and search through DOM to find the
    $('[id^="event_"]').each(function(i){
      var id = $(this).attr('id');
      var eventDate = $('#e_date_' + $(this).attr('id').substr( 6 ) ).val();
      // console.log( 'event date: ' + eventDate + ' criterial: date from - ' + df + ' date to - ' + dt );
      if( eventDate > df ){
        // console.log('event date ' + eventDate + ' is greater than - date from - ' + df);
        $(this).css('display','block');
      }
    });
    $(this).addClass('disabled');
    $(this).attr('aria-disabled', true);
  });


  $('#frmResume #form-input-selectFile').parent().addClass('pb-5 mb-0');

});
